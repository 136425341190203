.button {
    color: white;
}
.video-link {
    position: relative;
    filter: saturate(100%);
}
.video-link:hover {
    position: relative;
    filter: saturate(30%);
}
.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: #000;
}
.label {
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: white;
    opacity: 0;
    animation: all 1s ease-in-out;
    font-size: 24px;
}
.video-link:hover .label {
    opacity: 1;
}
.interviews-container {
    background-color: #000;
}