.video {    
    z-index: -1
}
.BackgroundVideo {
    background-color: #000;
}
.button {
    color: white;
}
.home-container {
    
}