.button-back {
    position: absolute;
    left: 20px;
    top: 20px;
}
.buttons-right {
    position: absolute;
    right: 20px;
    top: 20px;   
}
.button-back button {
    border: 0;
	cursor: pointer;
	outline: inherit;
}
.interview-container {
    background-color: #000;
}