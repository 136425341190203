.button-back {
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 99;
}
.button-back button {
    border: 0;
	cursor: pointer;
	outline: inherit;
}