.button-back {
    position: absolute;
    left: 20px;
    top: 20px;
}
.buttons-right {
    position: absolute;
    right: 20px;
    top: 20px;   
}
.button-back button {
    border: 0;
	cursor: pointer;
	outline: inherit;
}
.island,.manchester {
    margin-top: auto;
}
.island {

}
.manchester {
    
}