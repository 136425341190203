.splash-container {
    background-color: #000;
}
.splash-logo {
    margin-bottom: 100px;
}
.button {
    color: white;
}
@keyframes fadeIn { 
    from { opacity: 0.5; } 
  }
.button-enter {
    animation: fadeIn 500ms infinite alternate; 
    padding: 10px;
}
.button-enter:hover {
}